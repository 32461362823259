
export function errFullStack(error) {
  if (!error) {
    return null
  }
  let stack = error.stack
  if (error.cause) {
    const causeStack = errFullStack(error.cause)
    stack = `${stack}\n[cause] ${causeStack}`
  }
  return stack
}
