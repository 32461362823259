/**
 * Util function to send log events to lambda
 */
import { v4 as uuidv4 } from "uuid"
import { oktaTokenExpiresBefore, getOktaToken } from "./session-management.js"
import { parseJwt } from "customer-oauth2-token-management-utility"

const spaRequestId = uuidv4()

const logger = function (...args) {
  let oktaToken = null
  if (!oktaTokenExpiresBefore(0)) { // If the Okta token has expired
    oktaToken = getOktaToken()
  }

  // Don't attempt logger without an authorizationToken
  if (!oktaToken) {
    return
  }
  const tokenParsed = parseJwt(oktaToken)
  tokenParsed.sub = "<snip/>"
  let message = `${JSON.stringify({ tokenParsed })}\n`
  // Concatenate all of the arguments into a message string
  for (let index = 0; index < args.length; index++) {
    const arg = args[index]
    if (typeof arg === "object" && arg !== null) {
      // stringify objects
      message += `${JSON.stringify(arg, null, 2)}\n`
    } else {
      message += `${arg}\n`
    }
  }
  const event = JSON.stringify({ spaRequestId, message }, null, 2)
  try {
    fetch(`${process.env.REACT_APP_TING_API_URL}/logger`, {
      body: event,
      headers: {
        "Content-Type": "application/json",
        "authorizationToken": `Bearer ${oktaToken}`,
        "x-sf-ting-spaRequestId": spaRequestId
      },
      method: "POST"
    })
  } catch (error) {
    console.log("logger error: ", error)
  }
}

export const getSpaRequestId = () => spaRequestId

export default logger
