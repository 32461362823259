/*
Fetches the data from IOT Eligibility API
*/

import sleeper from "../utils/sleep-promise.js"
import logger from "../utils/logger.js"
import { refreshOktaToken, getOktaToken } from "../utils/session-management.js"
import { errFullStack } from "../utils/utils.js"

export async function getUserEligibility(tries = 1) {
  const headers = {}
  headers["Content-Type"] = "application/json"
  headers["Cache-Control"] = "private, max-age=0, must-revalidate"

  // See https://sfgitlab.opr.statefarm.org/pathfinder/iot-eligibility-api/-/blob/develop/doc/development/ConsumerMigration/ConsumerMigrationToAWS.md#calling-ioteligibilityapi
  if (process.env.REACT_APP_SF_TEST_LANE) {
    headers["SF-Lane"] = process.env.REACT_APP_SF_TEST_LANE
  }

  // Prioritize Okta
  if (await refreshOktaToken()) { // Refresh the token right before sending it downstream
    const oktaToken = getOktaToken()
    headers.Authorization = oktaToken ? `Bearer ${oktaToken}` : ""
  } else {
    throw new Error("Okta token refresh failed")
  }

  let iotCustResp = null

  try {
    iotCustResp = await fetch(
      `${process.env.REACT_APP_ELIGIBILITY_URL}`,
      {
        headers: headers,
        method: "GET"
      }
    )

    if (iotCustResp.status === 409) {
      iotCustResp.badResponse = true
      return iotCustResp
    } else if (![200, 401, 403].includes(iotCustResp.status)) {
      const err = Error("not 200, 401, or 403 status code from iot customer")
      err.errData = {
        iotCustStatusCode: iotCustResp.status,
        iotCustBodyText: await iotCustResp.text(),
        iotCustResp: iotCustResp
      }
      throw err
    }
    return iotCustResp
  } catch (error) {
    if (tries < process.env.REACT_APP_MAX_ENROLLMENT_TRIES) {
      // Wait time to try again gets incrementally longer
      await sleeper(tries * process.env.REACT_APP_ENROLLMENT_RETRY_WAIT_INTERVAL)
      // Recursively call getUserEligibility() up until REACT_APP_MAX_ENROLLMENT_TRIES when there's an error
      logger(
        "getUserEligibility error retry",
        { error },
        "errFullStack:", errFullStack(error)
      )
      return await getUserEligibility(tries + 1)
    }
    const err = Error("iot cust error after retries", { cause: error })
    err.errData = {
      iotCustStatusCode: iotCustResp?.status,
      causeErrData: error.errData
    }
    throw err
  }
}
